import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { valueCheckGlobal, getLoader } from '../../../GlobalFunctions';
import './cash.css';
// import Valuation from './../../valuationData';
import {
  baseUrl,
  modal_chart_icon,
  modal_table_icon,
  modal_excel_icon,
} from '../../../api/baseUrl';
import ChartModal from './../../../layout/modals/ChartModal';
import CashFlowTableModal from './../../../layout/modals/CashFlowTableModal';
import { Redirect, useHistory, Link, useLocation } from 'react-router-dom';
import Header from '../../../common/header/header';
import Search2 from '../search2';
import Nav from '../nav';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ComparisonChartModal from '../../../layout/modals/ComparisonChartModal';
const CashFlowTable = (props) => {
  const location = useLocation();

  let compId = location.state != undefined ? location.state.id : 189;
  const [companyId, setCompanyId] = useState(compId);
  const [company, setCompany] = useState({});

  const [data, setData] = useState([]);

  const [chartActive, setChartActive] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [label, setLabel] = useState('');
  const [chartData, setchartData] = useState([]);

  const [tableActive, setTableActive] = useState(false);

  const [cookies, setCookies, removeCookies] = useCookies();
  const [sectorData, setSectorData] = useState([]);

  const handleChartModal = (company_name, label, chartData) => {
    setCompanyName(company_name);
    setLabel(label);
    setchartData(chartData);
    setChartActive(true);
  };

  useEffect(() => {
    axios({
      method: 'post',
      url: `${baseUrl}/cashflowapi/` + companyId,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        setData(response.data);
        console.log(data);
      })
      .catch(function (response) {});
  }, [companyId]);

  const excel_name = company.name + ` - Cashflow Statement - PKR (mn)`;

  return (
    <>
      <Header />
      <Search2
        companyId={companyId}
        setCompanyId={setCompanyId}
        setCompany={setCompany}
      />
      <Nav companyId={companyId} />
      {data.length ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="cashflow-main statement-table">
              <span style={styles.excel}>
                <ReactHTMLTableToExcel
                  className="download-table-xls-button income-excel-btn"
                  table="table"
                  filename={excel_name}
                  sheet="file"
                  buttonText={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_excel_icon,
                      }}
                    />
                  }
                />{' '}
              </span>
              <table className="table table-responsive" id="table">
                <thead>
                  <tr>
                    <th colSpan={3}>Cashflow Statement - PKR (mn)</th>
                    {data[0].data.slice(-11).map((d, i) => (
                      <th key={i}>{d.year}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, i) => {
                    return (
                      <tr
                        className={d.label === 'Net Income' ? 'bold' : ''}
                        key={i}
                      >
                        <td colSpan={2} key={i}>
                          {d.label}
                        </td>
                        <td>
                          <span
                            style={styles.chart}
                            onClick={() => {
                              handleChartModal(company.name, d.label, d.data);
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: modal_chart_icon,
                              }}
                            />
                          </span>

                          <span style={styles.chart}>
                            <Link
                              // target="_blank"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  e.currentTarget.href,
                                  '_blank',
                                  'location = yes,height = 450,width = 950,scrollbars = yes,status = yes,left = 200),top = 100'
                                );
                              }}
                              to={{
                                pathname: `/comparison/cashflow/annual/${company.symbol}/${d.label}`,
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: modal_table_icon,
                                }}
                              />
                            </Link>
                          </span>
                        </td>
                        {d.data.map((_d, i) => (
                          <td key={i}>{valueCheckGlobal(_d.value)}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>{getLoader()}</>
      )}

      {chartActive && (
        <ComparisonChartModal
          chartActive={chartActive}
          setChartActive={setChartActive}
          companyname={companyName}
          label={label}
          chartData={chartData}
          slice={false}
        />
      )}
    </>
  );
};

const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
};

export default CashFlowTable;
