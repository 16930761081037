import React, { useEffect, useState, useContext } from 'react';
import './morningbriefing.css';
import LoadingImage from '../../../assets/media/loader.gif';
import { SegmentedControl } from '@mantine/core';
import Nav1 from '../nav1';
import { Link } from 'react-router-dom';
import Header from '../../../common/header/header';
import companylogo from '../../../assets/media/images/akseer-and-alpha-logo.svg';
import weblogo from '../../../assets/media/images/jamapunji-logo.png';
import axios from 'axios';
import { baseUrl, pdf } from '../../../api/baseUrl';
import {
  valueCheckGlobal,
  addDigit,
  addDigitWithSign,
} from '../../../GlobalFunctions';
import flowchart from '../../../assets/media/images/flowchart.png';
//import Chart from './Chart';
const MB = (props) => {
  //const [frequency, setFrequency] = useState('cpi');
  //const [investment, setInvestment] = useState([]);
  const [morningBriefing, setMorningBriefing] = useState([]);
  const [isLoadingMb, setIsLoadingMb] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  //const [tab, setTab] = useState('Chart');

  useEffect(() => {
    axios
      .get(`${baseUrl}/morningbriefingchart`)
      .then(function (response) {
        setMorningBriefing(response.data);
        console.log(123, response.data);
        setIsLoadingMb(true);
      })
      .catch(function (error) {
        console.log('morningbriefingchart====>', error);
      });
  }, []);

  let boldHeadings = [
    'Gross profit',
    'Operating profit',
    'EBITDA',
    'DPS',
    'Profit before tax',
    'Profit after tax',
  ];
  const headingCheck = (heading) => {
    return boldHeadings.includes(heading);
  };

  let color_class = '';
  return (
    <>
      <Header />
      <div className="investment-main analyst">
        <div className="row investmentboxed" style={styles.navWidth}>
          <Nav1 nopadding={true} />
        </div>
      </div>

      <div className="dashboard-main container container_box">
        {/* Briefing page */}
        {isLoadingMb ? (
          <>
            <div className="tab-morning-briefing">
              <div className="pdf_icon text-right m-0">
                {/* <button className="p-0"> */}
                <a href={morningBriefing.pdf} rel="noreferrer">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pdf,
                    }}
                  />
                </a>
                {/* </button> */}
              </div>
              <div className="main-briefing">
                <div
                  className="row container container_box pr-0 pl-0"
                  style={styles.companyname}
                >
                  <div className="col-sm-5 p-0 title briefing_amount">
                    <h1>{morningBriefing.date2}</h1>
                  </div>

                  <div
                    className="col-sm-5 logo investment-imgs pr-0"
                    style={styles.companylogoimage}
                  >
                    <img src={companylogo} style={styles.companylogo} />
                  </div>
                </div>
                <div className="briefing-heading">
                  <h1>Morning Briefing</h1>
                </div>
                <div className="briefing-content mt-4">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-9 pl-0">
                        <div className="content-list">
                          {morningBriefing.news.map((value, index) => {
                            if (
                              value.type == 'Positive' ||
                              value.type == 'positive'
                            ) {
                              color_class = 'content-green';
                            } else if (
                              value.type == 'Negative' ||
                              value.type == 'negative'
                            ) {
                              color_class = 'content-red';
                            } else {
                              color_class = 'content-black';
                            }
                            return (
                              <div>
                                <span className="content-blue">
                                  {value.title}
                                </span>{' '}
                                <span className="content-brown">
                                  | {value.sector}
                                </span>{' '}
                                <span className={color_class}>
                                  | {value.type}
                                </span>
                                <p>
                                  {value.description}
                                  <Link
                                    to={{ pathname: value.link }}
                                    target="_blank"
                                  >
                                    Click here for more
                                  </Link>
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-3 p-0">
                        <div className="briefing-table">
                          <h1>Net LIPI/FIPI Position</h1>
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th></th>
                                <th>USD mn</th>
                                <th>CYTD</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {console.log(morningBriefing.net)} */}
                              {morningBriefing.net.map((value, index) => {
                                return (
                                  <tr>
                                    <td>{value.label}</td>
                                    <td>{addDigitWithSign(value.value, 2)}</td>
                                    <td>{addDigitWithSign(value.cy, 2)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="briefing-table">
                          <h1>FIPI Sector-wise </h1>
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th></th>
                                <th>USD mn</th>
                              </tr>
                            </thead>
                            <tbody>
                              {morningBriefing.sector.map((value, index) => {
                                return (
                                  <tr>
                                    <td>{value.label}</td>
                                    <td>{addDigitWithSign(value.value, 2)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="briefing-table">
                          <h1>Major Indices</h1>
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th>Index</th>
                                <th>Value</th>
                                <th>Change</th>
                                <th>FYTD</th>
                                <th>CYTD</th>
                              </tr>
                            </thead>
                            <tbody>
                              {morningBriefing.indices.map((value, index) => {
                                return (
                                  <tr>
                                    <td>{value.label}</td>
                                    <td>{addDigitWithSign(value.value)}</td>
                                    <td>
                                      {addDigitWithSign(value.change, 1)}%
                                    </td>
                                    <td>{addDigitWithSign(value.fytd, 0)}%</td>
                                    <td>{addDigitWithSign(value.cytd, 0)}%</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="briefing-table">
                          <h1>Commodities</h1>
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Unit</th>
                                <th>Price</th>
                                <th>Change</th>
                              </tr>
                            </thead>
                            <tbody>
                              {morningBriefing.commodities.map(
                                (value, index) => {
                                  return (
                                    <tr>
                                      <td>{value.label}</td>
                                      <td>{value.unit}</td>
                                      <td>
                                        {addDigitWithSign(value.value, 2)}
                                      </td>
                                      <td>
                                        {addDigitWithSign(value.change, 2)}%
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="briefing-table">
                          <h1 className="pt-3 pb-3"></h1>
                          <table className="table table-responsive">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Last Close</th>
                                <th>Change</th>
                                <th>CYTD %</th>
                              </tr>
                            </thead>
                            <tbody>
                              {morningBriefing.currency.map((value, index) => {
                                return (
                                  <tr>
                                    <td>{value.label}</td>
                                    <td>{addDigitWithSign(value.value, 3)}</td>
                                    <td>
                                      {addDigitWithSign(value.change, 2)}%
                                    </td>
                                    <td>{addDigitWithSign(value.cytd, 2)}%</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="briefing_research">
                          <h1>Akseer Research</h1>
                          <p>
                            <Link to="/">info@akseerresearch.com</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="briefing_bottom mt-3 mb-3">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-9 pl-0 m-auto">
                        <div className="akseer-head">
                          <h1>Akseer Research (Pvt) Limited</h1>
                        </div>
                      </div>
                      <div className="col-md-3 pr-0">
                        <div className="web_link">
                          <h1>
                            <Link to="/">www.jamapunji.pk</Link>
                          </h1>
                          <img src={weblogo} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{ width: '100%', height: '100%', color: '#426fd7' }}
            className="main-loader d-flex flex-column justify-content-center align-items-center"
          >
            <img src={LoadingImage} style={{ height: '500px' }} />
          </div>
        )}
      </div>
    </>
  );
};
const styles = {
  companylogo: {
    width: '280px',
    float: 'right',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '100%',
    fontSize: '14px',
    justifyContent: 'space-between',
    marginLeft: '0px',
    marginRight: '0px',
  },
};
export default MB;
