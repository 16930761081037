import React, { useEffect, useState, useContext } from 'react';
import './energy.css';
import LoadingImage from '../../../assets/media/loader.gif';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import Nav from '../nav';
import Header from '../../../common/header/header';
import { Tabs } from '@mantine/core';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import axios from 'axios';
import {
  baseUrl,
  modal_excel_icon,
  modal_chart_icon,
} from '../../../api/baseUrl';
import EnergyChartModal from '../../../layout/modals/EnergyChartModal';
import { valueCheckGlobal } from '../../../GlobalFunctions';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ComparisonChartModal from '../../../layout/modals/ComparisonChartModal';

const Energy = (props) => {
  const [energy, setMmc] = useState([{ name: 'sulaiman' }]);

  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [table, setTable] = useState([]);
  const [title, setTitle] = useState('');
  const [chartActive, setChartActive] = useState(false);
  const [chartData, setchartData] = useState([]);
  const [label, setLable] = useState('');

  const filteData = (code) => {
    let newArray = allData.filter(function (el) {
      return el.code == code;
    });
    setTable(newArray);
  };

  const head = [
    { year: '2011', key: 'data_2011' },
    { year: '2012', key: 'data_2012' },
    { year: '2013', key: 'data_2013' },
    { year: '2014', key: 'data_2014' },
    { year: '2015', key: 'data_2015' },
    { year: '2016', key: 'data_2016' },
    { year: '2017', key: 'data_2017' },
    { year: '2018', key: 'data_2018' },
    { year: '2019', key: 'data_2019' },
    { year: '2020', key: 'data_2020' },
    { year: '2021', key: 'data_2021' },
    { year: '2022', key: 'data_2022' },
  ];

  const handleChartModal = (chartData) => {
    // console.log(chartData);
    setLable(chartData.description);
    setchartData(chartData);
    setChartActive(true);
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/energyyearbook`)
      .then(function (response) {
        setAllData(response.data);
        console.log(response.data);
        setIsLoading(true);
      })
      .catch(function (error) {
        console.log('energyyearbook====>', error);
      });
  }, []);

  const [opened, { open, close }] = useDisclosure(false);

  // console.log(head.length - 1, head);
  const year = head[head.length - 1].year;
  const pervious = year - 1;

  const handleModal = (code, title) => {
    filteData(code);
    setTitle(title);
    // console.log(code, table);
    open();
  };

  return (
    <>
      <Header />
      <div className="energy-main analyst">
        <div className="row energyboxed" style={styles.navWidth}>
          <Nav nopadding={true} />
        </div>
      </div>

      {allData.length && isLoading ? (
        <>
          <div className="dashboard-main container container_box">
            <div className="main_tabs_listing">
              <Tabs variant="outline" defaultValue="Energy Situation">
                <Tabs.List>
                  <Tabs.Tab value="Energy Situation">Energy Situation</Tabs.Tab>
                  <Tabs.Tab value="Oil">Oil</Tabs.Tab>
                  <Tabs.Tab value="Gas">Gas</Tabs.Tab>
                  <Tabs.Tab value="Coal">Coal</Tabs.Tab>
                  <Tabs.Tab value="Electricity">Electricity</Tabs.Tab>
                  <Tabs.Tab value="Renewable Energy">Renewable Energy</Tabs.Tab>
                  <Tabs.Tab value="Appendices">Appendices</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Energy Situation">
                  <div className="col-md-12 pl-0 listing-respons">
                    <div className="section_row1">
                      {/* <div className="first_rows">
                      <label>Section A</label>
                      <label>ENERGY SITUATION</label>
                    </div> */}
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.1</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.1',
                                  'Primary Energy Supplies by Source'
                                )
                              }
                            >
                              Primary Energy Supplies by Source
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.2</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.2',
                                  'Final Energy Consumption by Source'
                                )
                              }
                            >
                              Final Energy Consumption by Source
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.3</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.3',
                                  `Primary Energy Balance Sheet ${pervious}-${year}`
                                )
                              }
                            >
                              Primary Energy Balance Sheet {pervious}-{year}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.4</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.4',
                                  `Energy Balances 2015-16 to ${pervious}-${year}`
                                )
                              }
                            >
                              Energy Balances 2015-16 to {pervious}-{year}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.5</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.5',
                                  'Energy Consumption by Sector'
                                )
                              }
                            >
                              Energy Consumption by Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.5.1</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.5.1',
                                  'Energy Consumption in Domestic Sector'
                                )
                              }
                            >
                              Energy Consumption in Domestic Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.5.2</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.5.2',
                                  'Energy Consumption in Commercial Sector'
                                )
                              }
                            >
                              Energy Consumption in Commercial Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.5.3</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.5.3',
                                  'Energy Consumption in Industrial Sector'
                                )
                              }
                            >
                              Energy Consumption in Industrial Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.5.4</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.5.4',
                                  'Energy Consumption in Agriculture Sector'
                                )
                              }
                            >
                              Energy Consumption in Agriculture Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.5.5</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.5.5',
                                  'Energy Consumption in Transport Sector'
                                )
                              }
                            >
                              Energy Consumption in Transport Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.5.6</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.5.6',
                                  'Energy Consumption in Other Government Sector'
                                )
                              }
                            >
                              Energy Consumption in Other Government Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.6</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.6',
                                  'Fossil Fuels Consumption in Thermal Power Generation'
                                )
                              }
                            >
                              Fossil Fuels Consumption in Thermal Power
                              Generation
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">A.7</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'A.7',
                                  'Energy and Non-Energy Uses of Gas in Fertilizer Sector'
                                )
                              }
                            >
                              Energy and Non-Energy Uses of Gas in Fertilizer
                              Sector
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="Oil">
                  <div className="Tabs_energy">
                    <div className="col-md-6 listing-respons pl-0">
                      <div className="section_row1">
                        {/* <div className="first_rows">
                        <label>Section b</label>
                        <label>OIL</label>
                      </div> */}
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.1</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.1',
                                    `Crude Oil Reserves as on June 30, ${year}`
                                  )
                                }
                              >
                                Crude Oil Reserves as on June 30, {year}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.2</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.2',
                                    'Crude Oil Production by Field'
                                  )
                                }
                              >
                                Crude Oil Production by Field
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.2.1</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.2.1',
                                    'Crude Oil Production by Company'
                                  )
                                }
                              >
                                Crude Oil Production by Company
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.3</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.3',
                                    'Refining Capacities as on 30th June'
                                  )
                                }
                              >
                                Refining Capacities as on 30th June
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.4</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.4',
                                    'Crude Oil Processed by Refineries'
                                  )
                                }
                              >
                                Crude Oil Processed by Refineries
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5',
                                    'Oil Refineries Production'
                                  )
                                }
                              >
                                Oil Refineries Production
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5.1</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5.1',
                                    'Production by Attock Refinery Ltd.'
                                  )
                                }
                              >
                                Production by Attock Refinery Ltd.
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5.2</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5.2',
                                    'Production by BYCO Petroleum Pakistan Ltd.'
                                  )
                                }
                              >
                                Production by BYCO Petroleum Pakistan Ltd.
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5.3</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5.3',
                                    'Production by BYCO Oil Pakistan Ltd.'
                                  )
                                }
                              >
                                Production by BYCO Oil Pakistan Ltd.
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5.4</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5.4',
                                    'Production by National Refinery Ltd.'
                                  )
                                }
                              >
                                Production by National Refinery Ltd.
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5.5</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5.5',
                                    'Production by Pak-Arab Refinery Ltd.'
                                  )
                                }
                              >
                                Production by Pak-Arab Refinery Ltd.
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5.6</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5.6',
                                    'Production by Pakistan Refinery Ltd.'
                                  )
                                }
                              >
                                Production by Pakistan Refinery Ltd.
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.5.7</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.5.7',
                                    'Production by ENAR Petroleum Refining Facility (EPRF)'
                                  )
                                }
                              >
                                Production by ENAR Petroleum Refining Facility
                                (EPRF)
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.6</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.6',
                                    'Consumption of Petroleum Products by Fuel'
                                  )
                                }
                              >
                                Consumption of Petroleum Products by Fuel
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 listing-respons pr-0">
                      <div className="section_row1">
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.7</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.7',
                                    'Consumption of Petroleum Products by Sector'
                                  )
                                }
                              >
                                Consumption of Petroleum Products by Sector
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.8</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.8',
                                    'Consumption of Petroleum Products by Region'
                                  )
                                }
                              >
                                Consumption of Petroleum Products by Region
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.9</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.9',
                                    `Petroleum Products Consumption by Sector ${pervious}-${year}`
                                  )
                                }
                              >
                                Petroleum Products Consumption by Sector{' '}
                                {pervious}-{year}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows oil_sec">
                          <div className="second_rows1 oil_sec1">
                            <div className="text-center">B.10</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.10',
                                    `Sectoral Consumption of POL Products by Region/Province ${pervious}-${year}`
                                  )
                                }
                              >
                                Sectoral Consumption of POL Products by
                                Region/Province {pervious}-{year}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.11</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal('B.11', 'Import of Crude Oil')
                                }
                              >
                                Import of Crude Oil
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.12</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.12',
                                    'Import of Petroleum Products'
                                  )
                                }
                              >
                                Import of Petroleum Products
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.13</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.13',
                                    'Export of Crude Oil and Petroleum Products'
                                  )
                                }
                              >
                                Export of Crude Oil and Petroleum Products
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.14</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.14',
                                    'Bunkering of Petroleum Products'
                                  )
                                }
                              >
                                Bunkering of Petroleum Products
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.15.1</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal('B.15.1', 'HOBC Sale Price')
                                }
                              >
                                HOBC Sale Price
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.15.2</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal('B.15.2', 'Gasoline Sale Price')
                                }
                              >
                                Gasoline Sale Price
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.15.3</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal('B.15.3', 'Kerosene Sale Price')
                                }
                              >
                                Kerosene Sale Price
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">B.15.4</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'B.15.4',
                                    'High Speed Diesel Oil Sale Price'
                                  )
                                }
                              >
                                High Speed Diesel Oil Sale Price
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="section_rows">
                        <div className="section_rows">
                          {/* <div className="first_rows">
                          <label>Section b</label>
                          <label>OIL (Continued)</label>
                        </div> */}
                          <div className=" second_rows">
                            <div className="second_rows1">
                              <div className="text-center">B.15.5</div>
                            </div>
                            <div>
                              <div>
                                <a
                                  variant="primary"
                                  onClick={() =>
                                    handleModal(
                                      'B.15.5',
                                      'Light Diesel Oil Sale Price'
                                    )
                                  }
                                >
                                  Light Diesel Oil Sale Price
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className=" second_rows">
                            <div className="second_rows1">
                              <div className="text-center">B.16</div>
                            </div>
                            <div>
                              <div>
                                <a
                                  variant="primary"
                                  onClick={() =>
                                    handleModal(
                                      'B.16',
                                      'Deregulated Furnace Oil Sale Price'
                                    )
                                  }
                                >
                                  Deregulated Furnace Oil Sale Price
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="Gas">
                  <div className="Tabs_energy">
                    <div className="section_row1 col-md-6 pl-0">
                      <div className="section_rows">
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.8</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal('C.8', 'Import of LNG')
                                }
                              >
                                Import of LNG
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.9</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.9',
                                    'LNG Consumption by Sector'
                                  )
                                }
                              >
                                LNG Consumption by Sector
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.10</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.10',
                                    'Natural Gas Consumption by Sector'
                                  )
                                }
                              >
                                Natural Gas Consumption by Sector
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.11</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.11',
                                    `Natural Gas Consumption by Province (${pervious}-${year})`
                                  )
                                }
                              >
                                Natural Gas Consumption by Province ({pervious}-
                                {year})
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows oil_sec">
                          <div className="second_rows1 row_changes">
                            <div className="text-center">C.12</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.12',
                                    'Natural Gas Supplies to Fertilizer & Power Sectors by Source'
                                  )
                                }
                              >
                                Natural Gas Supplies to Fertilizer & Power
                                Sectors by Source
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.13</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.13',
                                    'Number of Gas Consumers as on 30th June'
                                  )
                                }
                              >
                                Number of Gas Consumers as on 30th June
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.14</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.14',
                                    'Natural Gas Distribution Network as on 30th June'
                                  )
                                }
                              >
                                Natural Gas Distribution Network as on 30th June
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.15</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.15',
                                    'Natural Gas Consumer Prices'
                                  )
                                }
                              >
                                Natural Gas Consumer Prices
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">C.1</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'C.1',
                                  `Natural Gas Reserves as on June 30, ${year}`
                                )
                              }
                            >
                              Natural Gas Reserves as on June 30, {year}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section_row1 col-md-6 pr-0">
                      <div className="section_rows">
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.1.1</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.1.1',
                                    `Associated Gas Reserves as on June 30, ${year}`
                                  )
                                }
                              >
                                Associated Gas Reserves as on June 30, {year}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.2</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.2',
                                    'Natural Gas Production by Type/Province'
                                  )
                                }
                              >
                                Natural Gas Production by Type/Province
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.3</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.3',
                                    'Natural Gas Production by Company'
                                  )
                                }
                              >
                                Natural Gas Production by Company
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.4</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.4',
                                    'Non-Associated Gas Production by Field'
                                  )
                                }
                              >
                                Non-Associated Gas Production by Field
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.4.1</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.4.1',
                                    'Associated Gas Production by Field'
                                  )
                                }
                              >
                                Associated Gas Production by Field
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.4.2</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.4.2',
                                    'Non-Associated Gas Production by Field'
                                  )
                                }
                              >
                                Non-Associated Gas Production by Field
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.5</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal('C.5', 'LPG Supplies by Source')
                                }
                              >
                                LPG Supplies by Source
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.6</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.6',
                                    'LPG Consumption by Sector'
                                  )
                                }
                              >
                                LPG Consumption by Sector
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className=" second_rows">
                          <div className="second_rows1">
                            <div className="text-center">C.7</div>
                          </div>
                          <div>
                            <div>
                              <a
                                variant="primary"
                                onClick={() =>
                                  handleModal(
                                    'C.7',
                                    `LPG Consumption by Region/Sector ${pervious}-${year}`
                                  )
                                }
                              >
                                LPG Consumption by Region/Sector {pervious}-
                                {year}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="Coal">
                  <div className="section_row1">
                    <div className="section_rows">
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">D.1</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'D.1',
                                  `Pakistan Coal Reserves / Resources as on June 30, ${year}`
                                )
                              }
                            >
                              Pakistan Coal Reserves / Resources as on June 30,{' '}
                              {year}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">D.1.1</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'D.1.1',
                                  `Thar Coal Reserves / Resources as on June 30, ${year}`
                                )
                              }
                            >
                              Thar Coal Reserves / Resources as on June 30,{' '}
                              {year}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">D.2</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'D.2',
                                  'Production of Coal by Field'
                                )
                              }
                            >
                              Production of Coal by Field
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">D.3</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal('D.3', 'Import of Coal')
                              }
                            >
                              Import of Coal
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="section_row">
                    <div className="section_rows">
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">D.4</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'D.4',
                                  'Consumption of Coal by Sector'
                                )
                              }
                            >
                              Consumption of Coal by Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">D.5</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'D.5',
                                  'PMDC’s Average Selling Price of Coal'
                                )
                              }
                            >
                              PMDC’s Average Selling Price of Coal
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="Electricity">
                  <div className="section_row1">
                    <div className=" second_rows">
                      <div className="second_rows1">
                        <div className="text-center">E.11</div>
                      </div>
                      <div>
                        <div>
                          <a
                            variant="primary"
                            onClick={() =>
                              handleModal(
                                'E.11',
                                `Energy Data of Thermal Power Stations (${pervious}-${year})`
                              )
                            }
                          >
                            Energy Data of Thermal Power Stations ({pervious}-
                            {year})
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="section_row">
                    <div className="section_rows">
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.1</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.1',
                                  'Electricity Installed Capacity as on 30th June'
                                )
                              }
                            >
                              Electricity Installed Capacity as on 30th June
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.2</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.2',
                                  'Gross Generation by Source & Import of Electricity'
                                )
                              }
                            >
                              Gross Generation by Source & Import of Electricity
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.3</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.3',
                                  'Thermal Electricity Generation by Fuel'
                                )
                              }
                            >
                              Thermal Electricity Generation by Fuel
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.4</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.4',
                                  'Fuel Consumption for Thermal Power Generation'
                                )
                              }
                            >
                              Fuel Consumption for Thermal Power Generation
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.5</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.5',
                                  'Electricity Consumption by Sector'
                                )
                              }
                            >
                              Electricity Consumption by Sector
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.6</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.6',
                                  'Electricity Consumption by Region/Province'
                                )
                              }
                            >
                              Electricity Consumption by Region/Province
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows oil_sec">
                        <div className="second_rows1 row_changes">
                          <div className="text-center">E.7</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.7',
                                  `Sectoral Electricity Consumption by Region/Province ${pervious}-${year}`
                                )
                              }
                            >
                              Sectoral Electricity Consumption by
                              Region/Province {pervious}-{year}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.8</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.8',
                                  'Auxiliary Consumption by Source'
                                )
                              }
                            >
                              Auxiliary Consumption by Source
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.9</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal('E.9', 'Electricity Balances')
                              }
                            >
                              Electricity Balances
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className=" second_rows">
                        <div className="second_rows1">
                          <div className="text-center">E.10</div>
                        </div>
                        <div>
                          <div>
                            <a
                              variant="primary"
                              onClick={() =>
                                handleModal(
                                  'E.10',
                                  `Energy Data of Hydel Power Stations (${pervious}-${year})`
                                )
                              }
                            >
                              Energy Data of Hydel Power Stations ({pervious}-
                              {year})
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="Renewable Energy">
                  <div className="section_row1">
                    <div className=" second_rows oil_sec">
                      <div className="second_rows1 row_changes">
                        <div className="text-center">F.1</div>
                      </div>
                      <div>
                        <div>
                          <a
                            variant="primary"
                            onClick={() =>
                              handleModal(
                                'F.1',
                                `Electricity Generation by Renewable Energy Projects (${pervious}-${year})`
                              )
                            }
                          >
                            Electricity Generation by Renewable Energy Projects
                            ({pervious}-{year})
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="Appendices">
                  <div className="section_row1">
                    <div className=" second_rows">
                      <div className="second_rows1">
                        <div className="text-center">G.1</div>
                      </div>
                      <div>
                        <div>
                          <a
                            variant="primary"
                            onClick={() =>
                              handleModal(
                                'G.1',
                                'Oil & Gas Exploratory Wells Drilled in Pakistan'
                              )
                            }
                          >
                            Oil & Gas Exploratory Wells Drilled in Pakistan
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className=" second_rows oil_sec">
                      <div className="second_rows1 row_changes">
                        <div className="text-center">G.2</div>
                      </div>
                      <div>
                        <div>
                          <a
                            variant="primary"
                            onClick={() =>
                              handleModal(
                                'G.2',
                                'Oil & Gas Appraisal/Development Wells Drilled in Pakistan'
                              )
                            }
                          >
                            Oil & Gas Appraisal/Development Wells Drilled in
                            Pakistan
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className=" second_rows">
                      <div className="second_rows1">
                        <div className="text-center">G.3</div>
                      </div>
                      <div>
                        <div>
                          <a
                            variant="primary"
                            onClick={() =>
                              handleModal('G.3', 'Unit Conversions')
                            }
                          >
                            Unit Conversions
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className=" second_rows">
                      <div className="second_rows1">
                        <div className="text-center">G.4</div>
                      </div>
                      <div>
                        <div>
                          <a
                            variant="primary"
                            onClick={() =>
                              handleModal('G.4', 'Gross Calorific Values')
                            }
                          >
                            Gross Calorific Values
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className=" second_rows">
                      <div className="second_rows1">
                        <div className="text-center">G.5</div>
                      </div>
                      <div>
                        <div>
                          <a
                            variant="primary"
                            onClick={() =>
                              handleModal('G.5', 'List of Abbreviations')
                            }
                          >
                            List of Abbreviations
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tabs.Panel>
              </Tabs>
            </div>
            <div className="section_boxes">
              <div className="col-md-12">
                <div className="row"></div>
              </div>
            </div>
          </div>
          <Modal
            className="listing-modal"
            opened={opened}
            onClose={close}
            title={title}
            closeOnClickOutside={false}
          >
            <div className="listing-modal-table">
              <span style={styles.excel}>
                <ReactHTMLTableToExcel
                  className="download-table-xls-button"
                  table="table"
                  filename="table"
                  sheet="file"
                  buttonText={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal_excel_icon,
                      }}
                    />
                  }
                />{' '}
              </span>
              <table className="table table-responsive" id="table">
                <thead>
                  <tr>
                    <th scope="col">Source</th>
                    <th scope="col">Unit</th>
                    <th scope="col">&nbsp;</th>
                    {head.map((value) => {
                      return <th scope="col">{value.year}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {table.length
                    ? table.map((value, index) => {
                        return (
                          <tr>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: value.label,
                                }}
                              />
                            </td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: value.unit,
                                }}
                              />
                            </td>
                            <td>
                              <span
                                style={styles.chart}
                                onClick={() => {
                                  handleChartModal(value);
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: modal_chart_icon,
                                  }}
                                />
                              </span>
                            </td>

                            {head.map((head_value) => {
                              return (
                                <td>
                                  {valueCheckGlobal(
                                    value[head_value.key],
                                    '',
                                    2
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    : ''}
                </tbody>
              </table>
            </div>
          </Modal>

          {/* <EnergyChartModal
            chartActive={chartActive}
            setChartActive={setChartActive}
            head={head}
            chartData={chartData}
          /> */}
          {chartActive && (
            <ComparisonChartModal
              chartActive={chartActive}
              setChartActive={setChartActive}
              // companyname={companyName}
              label={label}
              chartData={chartData}
              slice={false}
              head={head}
            />
          )}
        </>
      ) : (
        <div
          style={{ width: '100%', height: '100%', color: '#426fd7' }}
          className="main-loader d-flex flex-column justify-content-center align-items-center"
        >
          <img src={LoadingImage} style={{ height: '500px' }} />
        </div>
      )}
    </>
  );
};
const styles = {
  chart: {
    cursor: 'pointer',
    float: 'left',
    posiiton: 'absolute',
    padding: '0px 10px 0 0px',
  },
  switchBtn: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    transform: 'scale(1.5)',
    marginRight: '23px',
  },
  lables: {
    width: '200px',
    float: 'right',
  },
  companylogo: {
    width: '240px',
    float: 'right',
  },
  companylogoimage: {
    margin: 'auto 0px',
  },
  navWidth: {
    width: '100%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  companyname: {
    width: '50%',
    fontSize: '14px',
    margin: 'auto',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  noContent: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.3',
  },
  noContentImage: {
    width: '70px',
  },
  position: {
    position: 'relative',
  },
  excel: {
    cursor: 'pointer',
    float: 'right',
  },
};
export default Energy;
